"use client";

import React, { useCallback, useEffect, useState } from "react";
import styles from "./currencyCalculator.module.scss";
import CurrencySelector from "./currencySelector/currencySelector";
import getCurrencySymbol from "./utils/getCurrencySymbol";
import sanitiseCurrencyInput from "./utils/sanitiseCurrencyInput";
import currency from "currency.js";
import Cta from "@JohnLewisPartnership/jlfs-ui-library/dist/components/cta/cta";
import foreignCurrencyUrlBuilder from "./utils/foreignCurrencyUrlBuilder";
import { ParsedRate } from "../../../src/utils/getRates/getRates";
import { useDebounce } from "../../hooks/useDebounce/useDebounce";
import { PrimaryButton } from "@JohnLewisPartnership/jl-design-system/dist/elements/button/Button";
import { dispatchAnalyticsEvent } from "@JohnLewisPartnership/jlfs-ui-library/dist/components/analytics/Analytics";
import Message from "@JohnLewisPartnership/jlfs-ui-library/dist/components/message/message";

export type TCurrencyCalculatorProps = {
  title: string;
  ctaLinkToSectionId?: string;
  currencyList: Array<ParsedRate>;
  timerDurationInSeconds?: number;
  rateError?: boolean;
};

const gbpCurrency: ParsedRate = {
  countryCode: "GB",
  countryName: "Great Britain",
  buyRate: "0",
  currencyCode: "GBP",
  currencyName: "Great British Pounds",
  sellRate: "0",
};

const defaultCurrencyCode = "EUR";

const defaultFresUrl =
  "https://foreigncurrency.johnlewisfinance.com/foreign-currency-converter?product=EURN&denomination=mixed&direction=BASEtoFX&exchange_rates_timestamp=2025130385700&rate=1.1633&intcmp=ordernowbutton-pagename-currencyconverter&product_type_selector=N&currency_selector=EUR&amount=0.00&fres_currency_amount_to=0.00";

export const calculateSelectedCurrency = (
  pounds: currency,
  sellrate: string,
  currencySymbol: string
): string => {
  const convertedCurrency = pounds.multiply(sellrate);
  const roundedCurrency = Number(
    Number(convertedCurrency).toPrecision(5)
  ).toFixed(2);
  return `${currencySymbol}${
    Number.isNaN(convertedCurrency.value) ? "" : roundedCurrency
  }`;
};

export const calculatePounds = (pounds: currency, sellRate: string): string => {
  const convertedPounds = pounds.divide(sellRate);
  return `£${Number.isNaN(convertedPounds.value) ? "" : convertedPounds}`;
};

export const roundSelectedCurrency = (selectedCurrency: number): string => {
  if (selectedCurrency < 100000) {
    // round up if 10s is 1 or more otherwise round down
    if (selectedCurrency % 10 >= 1) {
      return `${currency(selectedCurrency, {
        increment: 10,
      }).add(5)}`;
    } else {
      return `${currency(selectedCurrency, {
        increment: 10,
      })}`;
    }
  }
  // pad with 0s if value is more than 5 digits (for large denominations like VND)
  return `${Number(Number(selectedCurrency).toPrecision(5)).toFixed(2)}`;
};

const extractCurrencyValue = (
  selectedCurrencyInputValue: string,
  selectedCurrencySymbol: string
): string | undefined => {
  const parts = selectedCurrencyInputValue.split(selectedCurrencySymbol);
  return parts.length > 1 ? parts[1] : undefined;
};

function CurrencyCalculatorCC({
  title,
  ctaLinkToSectionId = "",
  currencyList,
  timerDurationInSeconds = 1.5,
  rateError,
}: TCurrencyCalculatorProps) {
  const MIN_ORDER = 0;
  const MAX_ORDER = 2500;

  const defaultSelectedCurrency =
    currencyList.find((cur) => cur.currencyCode === defaultCurrencyCode) ??
    currencyList[0];
  const [selectedCurrency, setSelectedCurrency] = useState<ParsedRate>(
    defaultSelectedCurrency
  );
  const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState<string>(
    getCurrencySymbol(selectedCurrency?.currencyCode)
  );
  const [poundsInputValue, setPoundsInputValue] = useState<string>("£");
  const [selectedCurrencyInputValue, setSelectedCurrencyInputValue] =
    useState<string>(selectedCurrencySymbol);
  const [minMaxErrorIsActive, setMinMaxErrorIsActive] =
    useState<boolean>(false);
  const [timerSecondsLeft, setTimerSecondsLeft] = useState<number>(
    timerDurationInSeconds
  );
  const [timerIsActive, setTimerIsActive] = useState<boolean>(false);

  const openNewTab = (e: React.MouseEvent) => {
    e.preventDefault();
    if (typeof window === "undefined") return;

    const tDate = new Date();
    const splitDate = tDate.toLocaleDateString().split("/");
    const localYear = splitDate[2];
    const localMonth = splitDate[0];
    const localDay = splitDate[1];
    const splitTime = tDate
      .toLocaleTimeString([], { hour12: false })
      .split(" ");
    const localTime = parseInt(splitTime[0].replace(/[.\\/:-]/g, ""));
    const dateString = `${localYear}${localMonth}${localDay}${localTime}`;

    const exchangeUrl = foreignCurrencyUrlBuilder(
      selectedCurrency?.currencyCode ?? "EUR",
      currency(poundsInputValue).toString() ?? "0",
      currency(selectedCurrencyInputValue).toString() ?? "0",
      selectedCurrency?.sellRate ?? "0",
      dateString
    );

    dispatchAnalyticsEvent("currencyConverterOrderNowClick", {
      linkUrl: exchangeUrl,
      linkText: "Order now",
      currency: selectedCurrency?.currencyCode || "EUR",
      currencyType: "",
      sourceAmount: extractCurrencyValue(poundsInputValue, "£"),
      destinationAmount: extractCurrencyValue(
        selectedCurrencyInputValue,
        selectedCurrencySymbol
      ) || "",
    });
    window.open(exchangeUrl, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    dispatchAnalyticsEvent("fxCalculatorCTA", {
      calculatorAction: "Currency Change",
      calculatorDetail: selectedCurrency?.currencyCode,
    });
  }, [selectedCurrency]);

  const startRoundingTimer = useCallback(() => {
    setTimerSecondsLeft(timerDurationInSeconds);
    if (timerIsActive === false) setTimerIsActive(true);
  }, [
    timerDurationInSeconds,
    timerIsActive,
    setTimerSecondsLeft,
    setTimerIsActive,
  ]);

  const endRoundingTimer = useCallback(() => {
    setTimerSecondsLeft(timerDurationInSeconds);
    setTimerIsActive(false);
  }, [timerDurationInSeconds, setTimerIsActive]);

  const handleMinMaxError = (value: number): void => {
    (value > MAX_ORDER || value < MIN_ORDER) && value !== 0
      ? setMinMaxErrorIsActive(true)
      : setMinMaxErrorIsActive(false);
  };

  const disableOrderButton =
    Number(poundsInputValue.slice(1)) < MIN_ORDER ||
    Number(poundsInputValue.slice(1)) > MAX_ORDER;

  const { reset: debouncedMinMaxError } = useDebounce(handleMinMaxError, 1000);

  const handlePoundsInputValueUpdated = (value: string): void => {
    const sanitisedInputValue = sanitiseCurrencyInput("£", value);
    setPoundsInputValue(sanitisedInputValue);
    const pounds = currency(sanitisedInputValue);
    const newSelectedCurrencyValue = calculateSelectedCurrency(
      pounds,
      selectedCurrency.sellRate,
      selectedCurrencySymbol
    );
    setSelectedCurrencyInputValue(newSelectedCurrencyValue);
    debouncedMinMaxError(pounds.value);
    startRoundingTimer();
  };

  const handleSelectedCurrencyInputValueUpdated = useCallback(
    (value: string): void => {
      const sanitisedInputValue = sanitiseCurrencyInput(
        selectedCurrencySymbol,
        value
      );
      setSelectedCurrencyInputValue(sanitisedInputValue);
      const pounds = calculatePounds(
        currency(sanitisedInputValue),
        selectedCurrency.sellRate
      );
      setPoundsInputValue(pounds);
      debouncedMinMaxError(currency(pounds).value);
      startRoundingTimer();
    },
    [selectedCurrency?.sellRate, selectedCurrencySymbol, startRoundingTimer]
  );

  const handleNewCurrencySelected = (newCurrency: ParsedRate): void => {
    setSelectedCurrency(newCurrency);
    const newCurrencySymbol = getCurrencySymbol(newCurrency.currencyCode);
    setSelectedCurrencySymbol(newCurrencySymbol);
    poundsInputValue === "£"
      ? setSelectedCurrencyInputValue(newCurrencySymbol)
      : setSelectedCurrencyInputValue(
          calculateSelectedCurrency(
            currency(poundsInputValue),
            newCurrency.sellRate,
            newCurrencySymbol
          )
        );
    if (poundsInputValue !== "£") startRoundingTimer();
  };

  const handleInputFocusUpdated = (value: string): void => {
    if (value.includes("£")) {
      if (currency(value).value === 0) setPoundsInputValue("£");
    } else if (value.includes(selectedCurrencySymbol)) {
      if (currency(value).value === 0)
        setSelectedCurrencyInputValue(selectedCurrencySymbol);
    }
  };

  useEffect(() => {
    if (!timerIsActive) {
      if (poundsInputValue !== "£") {
        dispatchAnalyticsEvent("currencyConverterInteraction", {
          currency: selectedCurrency.currencyCode,
          currencyType: "",
          sourceAmount: extractCurrencyValue(poundsInputValue, "£"),
          destinationAmount: extractCurrencyValue(
            selectedCurrencyInputValue,
            selectedCurrencySymbol
          ),
        });
      }

      return;
    }

    const interval = setInterval(() => {
      if (timerSecondsLeft > 0) setTimerSecondsLeft(timerSecondsLeft - 0.1);
    }, 100);

    if (timerSecondsLeft <= 0) {
      clearInterval(interval);
      handleSelectedCurrencyInputValueUpdated(
        roundSelectedCurrency(
          currency(selectedCurrencyInputValue.split(selectedCurrencySymbol)[1])
            .value
        )
      );
      endRoundingTimer();
      return;
    }

    return () => clearInterval(interval);
  }, [
    timerIsActive,
    timerSecondsLeft,
    timerDurationInSeconds,
    selectedCurrencySymbol,
    selectedCurrencyInputValue,
    endRoundingTimer,
    handleSelectedCurrencyInputValueUpdated,
  ]);

  return (
    <div
      className={styles.currencyCalculator}
      data-testid="currency-calculator"
    >
      <div className={styles.topSection}>
        <h2 className={styles.title}>{title}</h2>
        <span className={styles.subheading}>
          Minimum order £250 - Maximum £2,500
        </span>
      </div>
      {rateError && (
        <div className={styles.errorMessage}>
          <Message
            title="We're unable to display exchange rates at the moment."
            type="warning"
            htmlContent={`<span>Please try again later or view our <a href="${defaultFresUrl}" target="_blank">order travel money page</a></span>`}
          />
        </div>
      )}
      <div className={styles.bottomSection}>
        {!rateError && (
          <span className={styles.inputSection}>
            <span className={styles.inputContainer}>
              <CurrencySelector
                label="Amount in British Pounds"
                selectedCurrency={gbpCurrency}
                inputValue={poundsInputValue}
                dropdownEnabled={false}
                inputValueUpdated={handlePoundsInputValueUpdated}
                error={
                  minMaxErrorIsActive
                    ? "Please enter an amount in GBP less than £2,500"
                    : ""
                }
                focusUpdated={handleInputFocusUpdated}
              ></CurrencySelector>
            </span>
            <span className={styles.separator}>=</span>
            <span className={styles.inputContainer}>
              <CurrencySelector
                label="What you’ll get in your currency"
                currencyList={currencyList.filter(
                  (currency) =>
                    currency.currencyCode !== selectedCurrency.currencyCode
                )}
                selectedCurrency={selectedCurrency}
                inputValue={selectedCurrencyInputValue}
                inputValueUpdated={handleSelectedCurrencyInputValueUpdated}
                selectedCurrencyUpdated={handleNewCurrencySelected}
                error={minMaxErrorIsActive ? " " : ""}
                focusUpdated={handleInputFocusUpdated}
              ></CurrencySelector>
            </span>
          </span>
        )}
        {!rateError && (
          <div className={styles.onlineRate}>
            <span>Online rate: </span>
            <span className={styles.sellRate}>{`£1 = ${selectedCurrencySymbol}${
              selectedCurrency?.sellRate ?? ""
            } ${selectedCurrency?.currencyCode ?? ""}`}</span>
          </div>
        )}
        <span className={styles.ctaContainer}>
          {!rateError ? (
            <Cta
              type="underlined"
              target={
                ctaLinkToSectionId.charAt(0) === "#"
                  ? ctaLinkToSectionId
                  : `#${ctaLinkToSectionId}`
              }
              label="View today's rates"
            />
          ) : (
            <div></div>
          )}
          <PrimaryButton
            data-testid="primary-button"
            tag="a"
            href=""
            target="_blank"
            inline={true}
            rel="noopener noreferrer"
            disabled={
              disableOrderButton && currency(poundsInputValue).value !== 0
            }
            onClick={openNewTab}
            className={styles.orderNowButton}
          >
            Order now
          </PrimaryButton>
        </span>
      </div>
    </div>
  );
}

export default CurrencyCalculatorCC;
